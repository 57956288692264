import fetch from "../auth/FetchInterceptor";


const ProfileService = {}

ProfileService.getAccountProfile = function () {
    return fetch({
        url: '/Account/profile',
        method: 'get'
    })
}

ProfileService.changePassword = function (data) {
    return fetch({
        url: '/Account/changePassword',
        method: 'put',
        data: data
    })
}

ProfileService.editProfile = function (data) {
    return fetch({
        url: '/Account/edit-profile',
        method: 'put',
        data: data
    })
}

ProfileService.getAllCountry = function () {
    return fetch({
        url: '/Country/get-all',
        method: 'get'
    })
}

ProfileService.changeEmail = function (data) {
    return fetch({
        url: '/Account/change-email',
        method: 'put',
        data: data
    })
}

ProfileService.getAllCurrency = function () {
    return fetch({
        url: '/Currency/get-all-currencies',
        method: 'get'
    })
}

ProfileService.getAllDateFormat = function () {
    return fetch({
        url: '/DateFormat/get-all',
        method: 'get'
    })
}

ProfileService.deleteAccount = function () {
    return fetch({
        url: '/Account/delete-account',
        method: 'put'
    })
}

ProfileService.authorizationConfirmEmailAccept = function () {
    return fetch({
        url: '/Account/authorization-confirm-email-accept',
        method: 'post'
    })
}

ProfileService.authorizationConfirmEmail = function () {
    return fetch({
        url: '/Account/authorization-confirm-email',
        method: 'post'
    })
}

ProfileService.paymentTriggeredUser = function () {
    return fetch({
        url: '/Account/payment-triggered',
        method: 'put'
    })
}

ProfileService.getTransactionUser = function () {
    return fetch({
        url: '/TransactionUser/get-transactions-user',
        method: 'get'
    })
}
// unSubscription

ProfileService.unSubscriptionTransactionUser = function () {
    return fetch({
        url: '/TransactionUser/unsubscription',
        method: 'post'
    })
}

ProfileService.getInfoPricingPlan = function (countUser) {
    return fetch({
        url: `/TransactionUser/get-info-pricing-plan?countUser=${countUser}`,
        method: 'get'
    })
}

ProfileService.getPricingPlanUser = function () {
    return fetch({
        url: '/TransactionUser/get-pricing-plan-user',
        method: 'get'
    })
}

ProfileService.PricingPlanChecker = function () {
    return fetch({
        url: '/Account/user-pricing-plan-checker',
        method: 'get'
    })
}

export default ProfileService;