import fetch from 'auth/FetchInterceptor';
import AuthService from "./AuthService";
import axios from "axios";

const AddSubscriptionService = {}

AddSubscriptionService.postSubscriptions = function (data) {
    return fetch({
        url: '/CustomSubscription/add',
        method: 'post',
        data: data
    })
}

AddSubscriptionService.postSubscriptionsByServiceId = function (data) {
    return fetch({
        url: '/CustomSubscription/add-by-service-id',
        method: 'post',
        data: data
    })
}

AddSubscriptionService.postQuickSubscriptions = function(data) {
    return fetch({
        url: '/CustomSubscription/quick-add',
        method: 'post',
        data: data
    })
}

AddSubscriptionService.putDetailsSubscriptions = function (data) {
    return fetch({
        url: '/CustomSubscription/edit-details',
        method: 'put',
        data: data
    })
}

AddSubscriptionService.getAllBillingType = function (){
    return fetch({
        url: '/SubscriptionBillingType/get-all-billing-type',
        method: 'get'
    })
}


// ApplicationService.editSubscriptions = function (data) {
//     return fetch({
//         url: `/CustomSubscription/edit?id=${data.id}`,
//         method: 'post',
//         data: data
//     })
// }


AddSubscriptionService.getCurrency = function () {
    return fetch({
        url: '/Currency/get-all-currencies',
        method: 'get'
    })
}

AddSubscriptionService.getUserCards = function () {
    return fetch({
        url: '/Card/get-cards-user',
        method: 'get'
    })
}

AddSubscriptionService.getPaymentMethod = function () {
    return fetch({
        url: '/UserCustomPaymentMethod/get-all-payments-methods',
        method: 'get'
    })
}

AddSubscriptionService.getBillingCycle = function () {
    return fetch({
        url: '/BillingCycle/get-all',
        method: 'get'
    })
}

AddSubscriptionService.getCategory = function () {
    return fetch({
        url: '/Category/get-all-categories-with-sub-categories',
        method: 'get'
    })
}

AddSubscriptionService.getRemindMe = function () {
    return fetch({
        url: '/RemindMe/get-all',
        method: 'get'
    })
}

AddSubscriptionService.getService = function ({ service }) {
    return fetch({
        url: `/Service/get-by-page?pageIndex=1&pageSize=1&searchString=${service}`,
        method: 'get'
    });
};


AddSubscriptionService.getSubscriptionType = function () {
    return fetch({
        url: '/SubscriptionType/get-all',
        method: 'get'
    })
}

AddSubscriptionService.getSubscriptionWithoutWorkspaceUser = function ({workspaceUserId}) {
    return fetch({
        url: `/Subscription/get-subscriptions-without-workspace-user?workspaceUserId=${workspaceUserId}`,
        method: 'get'
    })
}



export default AddSubscriptionService;