import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import ProfileService from "../../services/ProfileService";
import { signOut } from "./authSlice";
import AuthService from "../../services/AuthService";
import { AUTH_TOKEN, REFRESH_TOKEN } from "../../constants/AuthConstant";


export const getAccountProfile = createAsyncThunk('/Account/profile', async (data, { rejectWithValue }) => {
    try {
        const response =  await ProfileService.getAccountProfile();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const changePassword = createAsyncThunk('/Account/changePassword', async (data, { rejectWithValue }) => {
    try {
        const response =  await ProfileService.changePassword(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getAllCountry = createAsyncThunk('/All/country', async (data, { rejectWithValue }) => {
    try {
        const response =  await ProfileService.getAllCountry();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getAllCurrency = createAsyncThunk('/All/currency', async (data, { rejectWithValue }) => {
    try {
        const response =  await ProfileService.getAllCurrency();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getAllDateFormat = createAsyncThunk('/All/formatDate', async (data, { rejectWithValue }) => {
    try {
        const response =  await ProfileService.getAllDateFormat();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const editProfile = createAsyncThunk('/Account/editProfile', async (data, { rejectWithValue }) => {
    try {
        const response =  await ProfileService.editProfile(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const changeEmail = createAsyncThunk('/Account/changeEmail', async (data, { rejectWithValue }) => {
    try {
        const response =  await ProfileService.changeEmail(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const deleteAccountThunk = createAsyncThunk('/Account/delete-account', async (data, { rejectWithValue, dispatch }) => {
    try {
        const response =  await ProfileService.deleteAccount();
        dispatch(signOut())
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const paymentTriggeredUser = createAsyncThunk('/Account/payment-triggered', async (data, { rejectWithValue, dispatch }) => {
    try {
        const response =  await ProfileService.paymentTriggeredUser();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const authorizationConfirmEmail = createAsyncThunk('authorization/Confirm/Email', async (data, { rejectWithValue, dispatch }) => {
    try {
        const response =  await ProfileService.authorizationConfirmEmail();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});
export const authorizationConfirmEmailAccept = createAsyncThunk('authorization/Confirm/Email/Accept', async (data, { rejectWithValue, dispatch }) => {
    try {
        const response =  await ProfileService.authorizationConfirmEmailAccept();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const unSubscriptionTransactionUser = createAsyncThunk('unSubscription/liqpay/user', async (data, { rejectWithValue, dispatch }) => {
    try {
        const response =  await ProfileService.unSubscriptionTransactionUser();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});


export const getTransactionUser = createAsyncThunk('billing-info/transaction-user', async (data, { rejectWithValue, dispatch }) => {
    try {
        const response =  await ProfileService.getTransactionUser();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getInfoPricingPlan = createAsyncThunk('info/pricing-plan', async (countUser, { rejectWithValue, dispatch }) => {
    try {
        const response =  await ProfileService.getInfoPricingPlan(countUser);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getPricingPlanUser = createAsyncThunk('info/pricing-plan-user', async (data, { rejectWithValue, dispatch }) => {
    try {
        const response =  await ProfileService.getPricingPlanUser();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const PricingPlanChecker = createAsyncThunk('pricing-plan-checker', async (data, { rejectWithValue, dispatch }) => {
    try {
        const response =  await ProfileService.PricingPlanChecker();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});


const ProfileSlice = createSlice({
    name: 'account',
    initialState: {
        profile: [],
        country: [],
        currency: [],
        formatDate: [],
        transactionUser: [],
        pricingPlanId: [],
        pricingPlan: [],
        pricingPlanChecker: [],
        authorizationConfirmEmailData: [],
        authorizationConfirmEmaiAcceptlData: [],
        unSubscription: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(getAccountProfile.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAccountProfile.fulfilled, (state, action) => {
                state.loading = false
                state.profile = action.payload;
            })
            .addCase(getAccountProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(unSubscriptionTransactionUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(unSubscriptionTransactionUser.fulfilled, (state, action) => {
                state.loading = false
                state.unSubscription = action.payload;
            })
            .addCase(unSubscriptionTransactionUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(PricingPlanChecker.pending, (state)=> {
                state.loading = true;
            })
            .addCase(PricingPlanChecker.fulfilled, (state, action) => {
                state.loading = false
                state.pricingPlanChecker = action.payload;
            })
            .addCase(PricingPlanChecker.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getPricingPlanUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getPricingPlanUser.fulfilled, (state, action) => {
                state.loading = false
                state.pricingPlanId = action.payload;
            })
            .addCase(getPricingPlanUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getInfoPricingPlan.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getInfoPricingPlan.fulfilled, (state, action) => {
                state.loading = false
                state.pricingPlan = action.payload;
            })
            .addCase(getInfoPricingPlan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getTransactionUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getTransactionUser.fulfilled, (state, action) => {
                state.loading = false
                state.transactionUser = action.payload;
            })
            .addCase(getTransactionUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(authorizationConfirmEmailAccept.pending, (state)=> {
                state.loading = true;
            })
            .addCase(authorizationConfirmEmailAccept.fulfilled, (state, action) => {
                state.loading = false
                state.authorizationConfirmEmaiAcceptlData = action.payload;
            })
            .addCase(authorizationConfirmEmailAccept.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            .addCase(authorizationConfirmEmail.pending, (state)=> {
                state.loading = true;
            })
            .addCase(authorizationConfirmEmail.fulfilled, (state, action) => {
                state.loading = false
                state.authorizationConfirmEmailData = action.payload;
            })
            .addCase(authorizationConfirmEmail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAllCountry.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllCountry.fulfilled, (state, action) => {
                state.loading = false
                state.country = action.payload;
            })
            .addCase(getAllCountry.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAllDateFormat.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllDateFormat.fulfilled, (state, action) => {
                state.loading = false
                state.formatDate = action.payload;
            })
            .addCase(getAllDateFormat.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAllCurrency.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllCurrency.fulfilled, (state, action) => {
                state.loading = false
                state.currency = action.payload;
            })
            .addCase(getAllCurrency.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            .addCase(changePassword.pending, (state)=> {
                state.loading = true;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.loading = false
                state.change = action.payload; // fix change
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(editProfile.pending, (state)=> {
                state.loading = true;
            })
            .addCase(editProfile.fulfilled, (state, action) => {
                state.loading = false
                state.profile = action.payload; // fix change
            })
            .addCase(editProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(changeEmail.pending, (state)=> {
                state.loading = true;
            })
            .addCase(changeEmail.fulfilled, (state, action) => {
                state.loading = false
                state.profile = action.payload; // fix change
            })
            .addCase(changeEmail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(deleteAccountThunk.pending, (state)=> {
                state.loading = true;
            })
            .addCase(deleteAccountThunk.fulfilled, (state, action) => {
                state.loading = false
                state.profile = action.payload;
            })
            .addCase(deleteAccountThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(paymentTriggeredUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(paymentTriggeredUser.fulfilled, (state, action) => {
                state.loading = false
                state.profile = action.payload;
            })
            .addCase(paymentTriggeredUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },

});

export default ProfileSlice.reducer;
